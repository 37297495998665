var SITE_URL = window.location.origin;
$(document).ready(function() {

  //Contact form validation
  $('#contact-form').validate({
      rules: {
          name: {
              required: true, 
              maxlength:100
          },
          email: {
              required: true,
              email: true
          },
          phone: {
              required: true,
              maxlength:25
          },
          address: {
              required: true, 
          },
      },
      errorPlacement: function(error,element) {
          return true;
      },
      highlight: function(element) {
          $(element).parent('div').addClass('error');
      },
      unhighlight: function(element) {
          $(element).parent('div').removeClass('error');
      },
      onfocusout: function(element)
      {         
          /*tmpval = $(element).val().trim();
          if (tmpval == '') {            
            $(element).siblings('.formlabel').removeClass('labelup');
          } else {
            $(element).siblings('.formlabel').addClass('labelup');
          }
          return true;*/
      }
  });
});

// side bar products 
function openNav() {
  document.getElementById("mySidebar").style.width = "95%";
  //document.getElementById("main").style.marginLeft = "290px";
}

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  //document.getElementById("main").style.marginLeft= "0";
}

$(document).ready(function() {
    var owl = $("#owl-demo"); 
    owl.owlCarousel({
      items : 3, //10 items above 1000px browser width
      itemsDesktop : [1000,5], //5 items between 1000px and 901px
      itemsDesktopSmall : [900,2], // betweem 900px and 601px
      itemsTablet: [768,2], //2 items between 600 and 0
    itemsTablet: [1024,2], //2 items between 600 and 0
      itemsMobile : [480,1], // itemsMobile disabled - inherit from itemsTablet option
      pagination: false, 
      autoPlay: true, 
      slideSpeed: 1000
  });
  // Custom Navigation Events
  $(".next").click(function(){
    owl.trigger('owl.next');
  });
  $(".prev").click(function(){
    owl.trigger('owl.prev');
  });
});